<template>
    <v-container fluid style="padding:0;background-color:white" v-if="giveDebt">
        <table style="width:100%">
            <thead>
                <tr>
                    <td style="border:none">
                        <div class="header-space">&nbsp;</div>
                    </td>
                </tr>
            </thead>
            <tbody>

                <div class="content" style="height:100%">
                    <v-container v-if="giveDebt" fluid
                        style="margin-top:0;margin-bottom:0;padding-top:0;padding-bottom:100%">
                        <v-row>
                            <v-col>
                                <h3>
                                    ژمارەی پارەدان: {{ giveDebt.give_debt_id }}<br>
                                    بەروار: {{ giveDebt.date.split('T')[0] }} <br>
                                    ناوی کڕیار: {{ giveDebt.customer_name }} <br>
                                    قەرز: {{ giveDebt.previouse_debt.toFixed(2).toLocaleString() }}$ <br>
                                    واصلکردووە: {{ giveDebt.amount.toFixed(2).toLocaleString() }}$ <br>
                                    باقیات: {{ giveDebt.current_debt.toFixed(2).toLocaleString() }}$ <br>
                                    تێبنی: {{ giveDebt.note }}
                                </h3>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
            </tbody>
            <tfoot>
                <tr>
                    <td style="border:none">
                        <div class="footer-space">&nbsp;</div>
                    </td>
                </tr>
            </tfoot>
        </table>
        <div class="header" style="width: 100%;">
            <img src="@/assets/header.jpg" style="width:100%">

        </div>
        <div class="footer">
            <img src="@/assets/footer.jpg" style="width:100%">
        </div>


    </v-container>
</template>
  
<script>
export default {
    created() {
        this.getGiveDebt()

    },
    data() {
        return {
            giveDebt: null,

        }
    },
    methods: {
        async getGiveDebt() {
            const { data } = await this.axios.get('/give_debt/' + this.$route.params.give_debt_id)
            this.giveDebt = data

        },
    },
    updated() {
        setTimeout(() => {
            window.print();
            this.$router.back()
        }, 1000);
    }
}
</script>
  
<style scoped>
#app {
    background-color: rgb(255, 255, 255) !important;
}

#tablee {
    border: 1px solid black;
    border-collapse: collapse;
}

#tablee th,
td {
    border: 1px solid black;
    padding: 4px;
}

tr {
    background-color: rgb(255, 255, 255) !important;
}

td {
    font-size: 12px !important;
    height: 22px !important;
}

p {
    font-size: 12px !important;
}

/* #table{
      border: 1px solid black !important;
  } */



.header-space {
    height: 175px;
}

.footer-space {
    height: 100px;
}

.header {
    position: fixed;
    top: 10px;
    height: 50px;

}

.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;

}
</style>